
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_ANIMALS,
  components: {
    Vaccinate,
    CreateVaccine,
    AddWeight,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const animalList = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const animalId = ref<number>(0);
    const barnyardId = route.params["id"];
    const isLoading = ref<boolean>(false);

    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newWeight,
      () => {
        if (store.getters.newWeight) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      isLoading.value = true;

      animalList.value = [];
      originalAnimalList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      animalController
        .animalList(animalListModel)
        .then((response) => {
        if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            animalList.value = [];

            originalAnimalList.value = [];
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const search = ref<string>("");

    const searchItems = () => {
      if (search.value == "") {
        animalList.value = [...originalAnimalList.value];
      } else {
        animalList.value = originalAnimalList.value.filter(
          (animal) =>
            searchAnimalByName(animal, search.value) ||
            searchAnimalByEarTag(animal, search.value)
        );
      }
    };

    const searchAnimalByName = (animal, searchValue): boolean => {
      const animalName = animal.animal.name.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return animalName.indexOf(searchLowerCase) !== -1;
    };

    const searchAnimalByEarTag = (animal, searchValue): boolean => {
      const animalTag = animal.animal.earTag.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return animalTag.indexOf(searchLowerCase) !== -1;
    };

    const selectAnimal = (id) => {
      animalId.value = id;
      store.dispatch("selectedAnimalId", animalId.value);
    };

    const routeToAnimalInfo = (animal) => {
      router.push({ name: ROUTE_PAGES.ANIMAL_INFO, params: { id: animal.animal.id } });
    };

    const routeToCreateAnimal = () => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      PAGE_TITLE_PATH,
      barnyardId,
      currentPage,
      pageCount,
      isLoading,
      fetchAnimals,
      animalList,
      originalAnimalList,
      selectAnimal,
      pageSize,
      totalItems,
      search,
      searchItems,
      routeToAnimalInfo,
      routeToCreateAnimal,
    };
  },
});
